import React from "react"
import { Button, Media, Row, Col } from 'react-bootstrap'
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import CommonFlex from '../components/commonFlex'
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'
// import Vanta from '../components/Vanta'

import '../components/animate-index.css'

import './../assets/css/engine.css'
import './../components/media.css'
import './../assets/css/index.css'
import './../components/release.css'
import './../assets/css/aritclesDetail.css'
import './../assets/css/market-cases.css'
import './../assets/css/bootstrap.min.css'

let name = 'v4'


const ProductPage = () => {
  let [ hash, setHash ] = React.useState(null)

  const jumpTo = (url) => {
    navigate(url)
  }

  const engineObj = {
    'v4': {
      bannerData: {
        title: 'AIRIOT4.0版本全新上线',
        desc: '化繁为简 创新无限',
        banner: require('./../images/单帧10001.png'),
        key: 'engine-img-5',
        height: 547,
      },
      contentData: [
        {
          title: '数据采集与控制引擎',
          img: require('./../images/单帧10002.png'),
          tag_data: `<div>
            <h5 class="product-title">海量驱动</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">覆盖包括通用驱动、厂商驱动、行业驱动、定制驱动、网关驱动、云平台驱动等90%以上的驱动类型。分布式集群实现高可用性和可拓展性。</div>
            </div> 
            <h5 class="product-title">数据接口</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">强大的第三方系统快速集成能力，无限释放更多可能。</div>
            </div>
            <h5 class="product-title">报警能力</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">精准的报警逻辑</div>
              <div class="product-desc product-font">报警数据精准分析</div>
            </div>
          </div>`,
          name: 'model',
          url: '/engine?name=数据采集与控制引擎'
        },
        {
          title: '可视化组态引擎',
          img: require('./../images/单帧10003.jpg'),
          tag_data: `<div>
            <h5 class="product-title">强大的工艺流程图</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">一个画面秒级实时显示千级数据点</div>
            </div> 
            <h5 class="product-title">GIS地图</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">实时位置，实时报警，点、线、⾯资产显示</div>
            </div> 
            <h5 class="product-title">视频，强悍的GO开源流媒体框架</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽀持多种摄像头及流媒体格式</div>
            </div>
            <h5 class="product-title">三维展示能力</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽀持三维模型绑定三维空间中的物理参数</div>
              <div class="product-desc product-font">对接主流数字孪生平台</div>
            </div>
            <h5 class="product-title">⾃定义组件能⼒</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽆限扩展你的组件仓库</div>
            </div>
          </div>`,
          name: 'model',
          url: '/engine?name=可视化组态引擎'
        },
        {
          title: '数据分析引擎',
          img: require('./../images/单帧10004.png'),
          tag_data: `<div>
            <h5 class="product-title">基于平台数据的BI工具</h5>
            <div class="product-secord-box">
              <div class="product-secord-title">可视化的数据关联编辑</div>
              <div class="product-desc product-font">数据深度分析功能</div>
              <div class="product-desc product-font">数据分析数据源可与可视化画面直接打通</div>
            </div> 
            <h5 class="product-title">AI算法</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">结合机器学习框架</div>
              <div class="product-desc product-font">AI算法模型库</div>
            </div> 
          </div>`,
          name: 'model',
          url: '/engine?name=数据分析引擎'
        },
        {
          title: '业务流引擎',
          img: require('./../images/单帧10005.png'),
          tag_data: `<div>
            <h5 class="product-title">自研核心引擎，满足物联数据需求</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">可集群部署的流程引擎</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">满足高负载的事件触发</div>
            </div> 
            <h5 class="product-title">流程引擎轻松搞定业务流转</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">报警流程、设备联动</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">业务联动、数据处理</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">定时任务、审批工作流</div>
            </div> 
          </div>`,
          name: 'model',
          url: '/engine?name=业务流引擎'
        },
        {
          title: '二次开发引擎',
          img: require('./../images/单帧10006.png'),
          tag_data: `<div>
            <h5 class="product-title">可扩展是一个平台最基本的能力</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">AIRIOT所有引擎功能均可自由扩展开发</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">支持主流语言开发：Java、C#、GO、Node、Python等</div>
            </div> 
          </div>`,
          name: 'model',
          url: '/engine?name=二次开发引擎'
        },
      ]
    }
  }

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let windowHeight = document.documentElement.clientHeight

      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let animate_box = document.getElementsByClassName('product-media');

        let animate_box_left = document.getElementsByClassName('product-left-animate');
        let animate_box_right = document.getElementsByClassName('product-right-animate');

        let box = document.getElementsByClassName('img-bg-edit')
        let animate_box_media = document.getElementsByClassName('product-media')
 
        for(let i=0; i<animate_box.length;i++) {
          if(animate_box[i].offsetTop < (windowHeight + scrollTop)) {
            animate_box_left[i].style.animation = `fadeInLeft 500ms linear`
            animate_box_right[i].style.animation = `fadeInRight 500ms linear`
          } else {
            animate_box_left[i].style.animation = `fadeInRight 500ms linear`
            animate_box_right[i].style.animation = `fadeInLeft 500ms linear`
          }
        }

      })
      
      if(window.location.hash && window.location.hash.indexOf('#') >-1)  {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#')+1))
      }
    }
  },  [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('product-media');
      if(hash) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hash) {
            window.scrollTo({
              top: product_media[i].offsetTop -150
            })
          }
        }
      }
    }
  }, [hash])

  const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name) {
        return arr[1];
      }
    }
    return "";
  }

  const downPlat = () => {
    let cookieData = getCookie('data')
    if (cookieData && JSON.parse(cookieData)) {
      let contact_name = JSON.parse(cookieData).contact_name
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve")
    } else {
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve")
    }
  }

  const toHerf = () => {
    if(typeof window !== "undefined") {
      setHash('model')
      window.scrollTo({
        top: 1000
      })
    }
  }



  return (
    <div className="product-page engine-page">
      <div className='home-bg home-index engine-homa  engine-home-bg engine-home-4'>
        <Layout>
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
              <div className="engine-plat-common">
                <div className="plat-jumbotron-common">
                    <div className="jumbotron-left-common">
                        <div className="jumbotron-title-common">
                          <img src={require('./../images/airiot.png')} className="engine-img-left"/>
                          4.0版本
                          <span className="engine-jumbotron-span">重磅发布</span>
                        </div>
                        <div className="jumbotron-desc-common">{engineObj[name].bannerData.desc}</div>
                        <Button variant="primary" className="engine-button-product">
                          <a href="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank">获取产品体验</a>
                        </Button>
                    </div>
                </div>
                <div className="jumbotron-right-common" >
                    <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
                </div>
              </div>
        </Layout>
    </div>
    <>
    <Container className="content-box product-container">
            <CommonFlex title={engineObj[name].bannerData.title} desc=""></CommonFlex>
            </Container>
            <>
              {
                engineObj[name].contentData.map((item, index) => {
                  if(index%2 != 0) {
                    return (
                      <div style={{ background: '#eff0ff' }}>
                      <Container className="content-box product-container">
                      <div>
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99, padding: '2rem' } : { padding: '2rem'} }>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-left-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '96%', height: '100%'}}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                          <Col sm={6}  className="product-sm-animate product-right-animate" style={{ padding: '0 0 0 54px',  zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                                <div className="jumb-btn-sq">
                                  <Button onClick={() => jumpTo(item?.url)} className="jumb-btn">点击了解更多详情</Button>
                                </div>
                              </div>
                            </Media.Body>
                          </Col>
                        </Media>
                      </div>
                      </Container>
                      </div>
                    )
                  }else {
                    return (
                      <Container className="content-box product-container">
                      <div> 
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }           
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                          <Col sm={6} className="product-sm-animate product-left-animate" style={{ zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                                <div className="jumb-btn-sq">
                                  <Button onClick={() => jumpTo(item?.url)} className="jumb-btn">点击了解更多详情</Button>
                                </div>
                              </div>
                            </Media.Body>
                          </Col>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-right-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '100%', height: '90%' }}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                        </Media>
                      </div>
                      </Container>
                    )
                  }
                })
              }
            </>
          </>
      <Footer></Footer>
    </div>
  )
}

export default ProductPage

